<template>
   <div class="container h-100">

    

      <div class="h-75 pt-5">

        <div class="d-flex justify-content-center align-items-center h-100 flex-column">
         <div id="login_form_div">
            <div class="card-body">
               <p style="font-weight: 500; font-size: 28px; text-align: center;">What grade are you in? </p>
               <div>
                   
                    <div class="input-group my-4">
                      <select class="custom-select" id="inputGroupSelect04" v-model="cdata.selected_class">
                        <option selected>Choose...</option>
                        <option value="1">Grade 1</option>
                        <option value="2">Grade 2</option>
                        <option value="3">Grade 3</option>
                      </select>
                    </div>
                   
                   <button type="submit" class="btn w-100 auth_submit_btn" style="padding: 12px 24px;" @click="classnum_select" v-bind:disabled='cdata.system.buttons.common_without_symmary.is_button_blocked'>Start</button>


               </div>
            </div>
         </div>

        </div>

      </div>
   </div>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
            var cdata = {//Если не указать - не всключиться реактивность 
                /* COPYPASTE MODIFY */
                system: {
                    _route_: "g1_child_setup", /* MODIFY */
                    is_section: {// store/index  -> is_required_sections
                        center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                    },
                    is_stage: {// store/index  -> is_required_stages
                        center: false,
                    },
                    cnf: {},
                    buttons: {
                        common_without_symmary: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        0: "r_auth", //r_auth 
                                        1: "json_version", // 
                                    }
                        },
                        common: {
                            is_button_blocked: true,
                            button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                        },
                        button1: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        0: "r_auth", //r_auth 
                                        1: "json_version", // 
                                    }
                        },

                    },

                },
                errors: {
                    is_errors: false,
                    actions_error: {},
                },
                business_logic: {

                },
                
                screen_flags: {
                    show_grade_select: false,
                    show_connect_parent: false
                },
                
                selected_class: "Choose...",
            }

    export default {
        name: 'child_setup', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },

        created() { },

        mounted() {
            this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);
            this.$root._PAGE().on_mounted(this.$store, this.cdata); //обнулить флажки

            //IF NEEDED
            setTimeout(() => {
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });
                this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            }, 300);
            
            
        },
        computed: {

            /* COPYPASTE */
            ...mapGetters(['__blocked_tables', '__is_required_sections', '__is_required_stages', '__tables', '__is_errors']),
            _system() {
                let debug = {};
                try {
                    debug['tables'] = this.__tables(this.cdata['system']['_route_']);
                    debug['is_errors'] = this.__is_errors(this.cdata['system']['_route_']);
                    debug['blockings'] = this.__blocked_tables(this.cdata['system']['_route_']);
                    debug['sections'] = this.__is_required_sections(this.cdata['system']['_route_']);
                    debug['stages'] = this.__is_required_stages(this.cdata['system']['_route_']);
                } catch (ex) {
                    console.log(ex);
                }
                return debug;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    state['auth'] = this.$store.state['state_tables'].state_auth;/* MODIFY */
                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },

            form() {
                let form = {};// form.classnum


                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";
               
                 

                return form;
            }
            /* #ODIFY */
            /* #OPYPASTE */

        },

        watch: {

            /* COPYPASTE на каждое изменение при работе с таблицей два вызова на начало и завершение*/
            "form.cnt_loaded": {
                handler: function () {

                    
                    
                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после выполнения всех стадий, на любое действие с таблицами
                    if (this.$store.state.is_all_stage_fin)
                    { 
                        document.getElementById('await_loader_outer').style.display = 'none';
                        //console.log("EXECUTE AFTER ALL STAGE LOAD FIN [" + this.cdata.system._route_ + "]");
                    }  


                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли стадии, для текущей страницы 
                    
                    if (this.$store.state.system[this.cdata.system._route_].is_mounted_execute && !this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page) {
                        //проверить завершение всех стадий для страницы (срабатывал раньше времени)
                        //set cdata.system.is_stages_fin_for_THIS_page
                        // console.log("CALC is_stages_fin_for_THIS_page");
                        if (this.$root._SYS().check_is_stages_fin_for_this_page(this.$store, this.cdata))
                        {
                            this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': true, '_route_': this.cdata.system._route_ });
                            
                            this.$root._SYS().page_v_update(this.$store, this.cdata);
                            
                            
                            console.log("REGISTER ALL STAGE LOAD FIN - FOR PAGE [" + this.cdata.system._route_ + "]");
                        }


                    }

 
                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после загрузки всех стадий для страницы, на любое действие с таблицами
                    if (this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page)
                    {
                        //просто постоянно перещитываем счетчики и блокровки кнопок!
                        this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata, true);
                    }



                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },

        },

        methods: {
            
            classnum_select: async function() {
                let classnum = this.cdata.selected_class;
                
                if(classnum != "Choose..." && classnum != null) {
                    this.cdata.system.buttons.common_without_symmary.is_button_blocked = true;
                    this.$root._REQUEST_TO_PARENT_DASHBORD().update__child_grade(this.$store, classnum);
//                    await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().on_select_classnum_button__multi_ls_and_back(this.$store, this.cdata, classnum));
                    
                    await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().on_select_classnum_button__multi_ls_and_back(this.$store, this.cdata, classnum)).then(
                        this.$snotify.async('Getting everything prepared...', 'Loading', () => new Promise((resolve, reject) => {
                            setTimeout(() => resolve({
                              title: 'Welcome',
                              body: 'To our team!',
                              config: {
                                timeout: 3000,
                                showProgressBar: true,
                                pauseOnHover: true,
                                closeOnClick: true
                              }
                            }), 2000);
                        }))
                    );
                    
                    window.location.href = '/g1/desksweek/' + '?t=' + this.$root._SYS().add_v();
                }
            },

            move_to: function (new_route) { 
               this.$root._PAGE().move_to(this.$store, this.cdata, new_route); 
            },

        }


    }
</script>

<style lang="scss" scoped>
.auth_submit_btn {
  background: #55D18E;
  box-shadow: 0px 4px 0px #4FBC81;
  border-radius: 12px;
  color: white;
}

.auth_submit_btn:hover {
  color: white;
}
</style>
